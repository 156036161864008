<template>
  <div id="e-course-solution">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template>
      <div class="columns is-multiline">
        <div class="column is-8 is-offset-2">
          <p class="title is-5 has-text-right">
            {{ schoolClassName }} - {{ subjectName }}
          </p>
          <p class="subtitle is-6 has-text-right">{{ eCourseTopicName }}</p>
        </div>
        <div class="column is-8 is-offset-2">
          <div class="columns is-multiline">
            <div class="column is-3 primary py-0 py-1">Submitted:</div>
            <div class="column is-9 text-bg-color py-0 py-1">
              {{ submitted ? 'Yes' : 'No' }}
            </div>
            <div class="column is-3 primary py-0 py-1">Answered:</div>
            <div class="column is-9 text-bg-color py-0 py-1">
              {{ Object.keys(solutions).length }} of {{ questions.length }}
            </div>
            <div class="column is-3 primary py-0 py-1">Score:</div>
            <div class="column is-9 text-bg-color py-0 py-1">
              {{ submitted ? eCourseWorkSolution.score : 'Pending' }}
            </div>
          </div>
        </div>
        <div class="column is-8 is-offset-2" v-if="!submitted">
          <div class="buttons is-right">
            <button
              type="submit"
              class="button btn-120 is-primary is-capitalized"
              @click="initializeSolutionSubmission()"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </template>
    <!-- Delete -->
    <app-modal context="submit" v-on:submit="updateSubmitted">
      <template v-slot:add>
        <p>You are about to submit this course work.</p>
        <br />
        <p>After submitting you will not be able to make further changes.</p>
        <br />
        <p>Do you want to proceed?</p>
      </template>
    </app-modal>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchUser, fetchTerm } from '@/assets/js/app_info.js'
import { crudNotification } from '../../assets/js/notification'

export default {
  data() {
    return {
      pageTitle: 'E Course Solution Info',
      schoolId: null,
      eCourseWorkSolution: {},
      questions: [],
      solutions: {},
      schoolClassName: '',
      subjectName: '',
      eCourseTopicName: '',
      submitted: false,
    }
  },
  apollo: {
    eCourseWorkSolution: {
      query: gql`
        query ECourseWorkSolutionInfoForStudent(
          $eCourseWorkId: Int!
          $studentId: Int!
        ) {
          eCourseWorkSolution(
            eCourseWorkId: $eCourseWorkId
            studentId: $studentId
          ) {
            id
            solutions
            score
            submitted
            eCourseWork {
              id
              courseType
              duration
              questions
              eCourseTopic {
                name
                eCourse {
                  schoolClass {
                    name
                  }
                  subject {
                    name
                  }
                }
              }
              questions
            }
          }
        }
      `,
      variables() {
        return {
          eCourseWorkId: parseInt(this.$route.params.e_course_work_id),
          studentId: parseInt(this.$route.params.student_id),
        }
      },
      errors(error) {
        console.log(error)
      },
    },
  },
  watch: {
    eCourseWorkSolution(data) {
      if (data) {
        this.eCourseTopicName = data.eCourseWork.eCourseTopic.name
        this.schoolClassName =
          data.eCourseWork.eCourseTopic.eCourse.schoolClass.name
        this.subjectName = data.eCourseWork.eCourseTopic.eCourse.subject.name

        this.questions = data.eCourseWork.questions

        this.submitted = data.submitted

        this.solutions = data.solutions
      } else {
        this.$router.push(`/school/${this.schoolId}/e_courses`)
        this.$buefy.notification.open({
          duration: 5000,
          message: 'You have not started this course work.',
          position: 'is-top',
          type: 'is-info',
          hasIcon: true,
        })
      }
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    this.schoolId = parseInt(this.$route.params.school_id)
    this.studentId = parseInt(this.$route.params.tudent_id)
    this.eCourseWorkId = parseInt(this.$route.e_course_work_id)

    this.$store.commit('setSubMenus', [
      {
        name: 'E Courses',
        route: `/school/${this.schoolId}/e_courses`,
      },
    ])
  },
  methods: {
    initializeSolutionSubmission() {
      this.$store.commit('setAppModalActive', true)
    },
    updateSubmitted() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpdateECourseWorkSolutionSubmission($id: Int!) {
              updateECourseWorkSolutionSubmission(input: { id: $id }) {
                eCourseWorkSolution {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.eCourseWorkSolution.id),
          },
        })
        .then((response) => {
          const data = response.data.updateECourseWorkSolutionSubmission
          crudNotification(data.errors, 'Successfully submitted.').then(() => {
            this.$store.commit('setAppModalActive', false)
            this.$apollo.queries.eCourseWorkSolution.refetch()
          })
        })
        .catch((error) => {
          console.error(error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.e-courses-list {
  font-size: 0.8em;
  border-bottom: orange 1px solid;
  padding: 10px 10px;
}

.question-container {
  display: flex;
  margin-bottom: 5px;
}

.question-index {
  flex: 1 3%;
  font-weight: bold;
}

.question {
  flex: 1 97%;
  font-weight: bold;
}

.question-option {
  flex: 1 97%;
}

.options-container {
  padding-left: 16px;
}

.question-footer {
  display: flex;
  padding: 8px;
}

.question-footer > a {
  flex: 1;
}

.question-footer > a:nth-child(1) {
  flex: 2 75%;
}

.list {
  height: 900px;
  overflow-y: scroll;
  padding: 8px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
